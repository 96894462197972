import React from "react";
import { Link } from "react-router-dom"; // برای لینک‌دهی
import styles from "./TopServiceHeader.module.css";
import profileImage from "../../assets/images/freeface.jpg"; // مسیر عکس پروفایل
import serviceBG from "../../assets/images/ServiceBG.png"; // مسیر عکس بک‌گراند

const TopServiceHeader: React.FC = () => {
  return (
    <div className={styles.headerContainer}>
      {/* پس‌زمینه تصویر */}
      <div
        className={`${styles.backgroundImage} ${
          serviceBG ? "" : styles.gradientBackground
        }`}
      >
        {serviceBG && (
          <img
            src={serviceBG} // عکس بک‌گراند جدید
            alt="Service Background"
            className={styles.background}
          />
        )}
        <div className={styles.tint}></div> {/* لایه تیره */}
      </div>

      {/* دکمه بازگشت */}
      <Link to="/times" className={styles.backButton}>
        <div className={styles.vector}></div> {/* فلش به درستی ایجاد شده */}
      </Link>

      {/* اطلاعات سرویس */}
      <div className={styles.infoContainer}>
        <div className={styles.profileImageContainer}>
          <img
            src={profileImage} // عکس جدید
            alt="Stylist Profile"
            className={styles.profileImage}
          />
        </div>
        <div className={styles.textContainer}>
          <h1 className={styles.serviceTitle}>وقت رنگ و لایت مو</h1>
          <p className={styles.stylistName}>شادی رضایی</p>
        </div>
      </div>
    </div>
  );
};

export default TopServiceHeader;
