import React from "react";
import { FaPhoneAlt, FaMapMarkerAlt, FaInstagram } from "react-icons/fa";
import styles from "./ContactSection.module.css";

// تعریف آیکون‌ها و لینک‌ها به صورت یک آرایه جداگانه
const icons = [
  {
    icon: <FaPhoneAlt className={styles.iconPhone} />,
    label: "تماس",
    key: "phone",
    link: "tel:+1234567890",
  },
  {
    icon: <FaMapMarkerAlt className={styles.iconLocation} />,
    label: "موقعیت",
    key: "location",
    link: "https://maps.google.com",
  },
  {
    icon: <FaInstagram className={styles.iconInstagram} />,
    label: "اینستاگرام",
    key: "instagram",
    link: "https://instagram.com",
  },
];

const ContactSection: React.FC = () => (
  <div className={`container text-center ${styles.container}`}>
    <h5 className={styles.title}>ارتباط با ما</h5>
    <div className={`d-flex justify-content-center gap-3 ${styles.iconContainer}`}>
      {icons.map(({ icon, label, key, link }) => (
        <a
          key={key}
          href={link}
          aria-label={label}
          target="_blank"
          rel="noopener noreferrer"
          className={`d-flex justify-content-center align-items-center ${styles.iconWrapper}`}
        >
          {icon}
        </a>
      ))}
    </div>
  </div>
);

export default ContactSection;
