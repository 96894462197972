import React, { useState, useEffect, useCallback, memo } from "react";

interface FilterSectionProps {
  selectedStylist: string | null;
  selectedService: string | null;
  onFilterChange: (stylist: string | null, service: string | null) => void;
  services: string[];
  hairdressers: string[];
}

interface DropdownProps {
  title: string;
  items: string[];
  selectedItem: string | null;
  onItemSelect: (item: string | null) => void;
  isOpen: boolean;
  toggleDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = memo(
  ({ title, items, selectedItem, onItemSelect, isOpen, toggleDropdown }) => {
    return (
      <div
        className="position-relative w-100 mb-3"
        style={{ maxWidth: "400px" }}
      >
        <button
          className="btn w-100 d-flex align-items-center justify-content-between"
          onClick={toggleDropdown}
          style={{
            border: "1px solid #B7AFF7",
            borderRadius: "24px",
            backgroundColor: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#4d5389",
            padding: "10px 16px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              color: "#4d5389",
              transform: isOpen ? "rotate(90deg)" : "rotate(270deg)",
              transition: "transform 0.3s ease",
            }}
          >
            &lt;
          </span>
          <span style={{ fontSize: "14px", color: "#4d5389" }}>
            {selectedItem || title}
          </span>
        </button>
        {isOpen && (
          <ul
            className="position-absolute w-100"
            style={{
              borderRadius: "16px",
              border: "1px solid #B7AFF7",
              backgroundColor: "#fff",
              padding: "8px 0",
              maxHeight: "200px",
              overflowY: "auto",
              marginTop: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 10,
            }}
          >
            <li
              className="dropdown-item"
              style={{
                padding: "8px 16px",
                fontSize: "14px",
                color: "#4d5389",
                cursor: "pointer",
                direction: "rtl",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // افزودن برای تراز بهتر
              }}
              onClick={() => onItemSelect(null)}
            >
              <span>همه</span>
            </li>
            {items.map((item, index) => (
              <li
                key={index}
                className={`dropdown-item ${
                  selectedItem === item ? "active" : ""
                }`}
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  color: "#4d5389",
                  backgroundColor: selectedItem === item ? "#EFEDFF" : "#fff",
                  cursor: "pointer",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // برای تراز کردن تیک
                  direction: "rtl",
                }}
                onClick={() => onItemSelect(item)}
              >
                <span>{item}</span>
                {selectedItem === item && (
                  <span
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: "#695CD5",
                      color: "#FFF",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    ✔
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

const FilterSection: React.FC<FilterSectionProps> = ({
  selectedStylist,
  selectedService,
  onFilterChange,
  services,
  hairdressers,
}) => {
  const [localSelectedStylist, setLocalSelectedStylist] = useState<
    string | null
  >(selectedStylist);
  const [localSelectedService, setLocalSelectedService] = useState<
    string | null
  >(selectedService);
  const [openDropdown, setOpenDropdown] = useState<
    "services" | "stylists" | null
  >(null);

  useEffect(() => {
    setLocalSelectedStylist(selectedStylist);
    setLocalSelectedService(selectedService);
  }, [selectedStylist, selectedService]);

  const toggleDropdown = useCallback((dropdown: "services" | "stylists") => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  }, []);

  const updateFilter = useCallback(
    (stylist: string | null, service: string | null) => {
      setLocalSelectedStylist(stylist);
      setLocalSelectedService(service);
      onFilterChange(stylist, service);
    },
    [onFilterChange]
  );

  return (
    <div className="d-flex flex-column align-items-center px-3 py-2">
      <Dropdown
        title="سرویس‌ها"
        items={services}
        selectedItem={localSelectedService}
        onItemSelect={(service) => {
          updateFilter(localSelectedStylist, service);
          setOpenDropdown(null);
        }}
        isOpen={openDropdown === "services"}
        toggleDropdown={() => toggleDropdown("services")}
      />
      <Dropdown
        title="آرایشگرها"
        items={hairdressers}
        selectedItem={localSelectedStylist}
        onItemSelect={(stylist) => {
          updateFilter(stylist, localSelectedService);
          setOpenDropdown(null);
        }}
        isOpen={openDropdown === "stylists"}
        toggleDropdown={() => toggleDropdown("stylists")}
      />
    </div>
  );
};

export default FilterSection;
