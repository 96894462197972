// src/store/index.tsx
import { configureStore } from '@reduxjs/toolkit';
import tabReducer from './tabSlice';
import serviceReducer from './serviceSlice';

const store = configureStore({
  reducer: {
    tab: tabReducer,
    services: serviceReducer, // اضافه کردن serviceSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
