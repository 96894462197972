import React from "react";

interface TopSectionProps {
  logoSrc: string; // مسیر لوگو
  title: string;
  subtitle: string;
}

const TopSection: React.FC<TopSectionProps> = ({
  logoSrc,
  title,
  subtitle,
}) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        backgroundColor: "#EFEDFF",
        borderRadius: "0 0 1000px 1000px",
        width: "100%",
        height: "367px",
        position: "relative",
        overflow: "hidden",
        margin: "0 auto 10%",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "120px",
          height: "120px",
          backgroundColor: "white",
          borderRadius: "50%",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
        }}
      >
        <img
          src={logoSrc}
          alt="Logo"
          style={{
            width: "80%",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </div>
      <h1
        style={{
          marginTop: "0",
          marginBottom: "4px",
          fontSize: "32px",
          fontWeight: "bold",
          lineHeight: "48px",
          textAlign: "center",
          color: "#332f5c",
        }}
      >
        {title}
      </h1>
      <p
        style={{
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "27px",
          color: "#332f5c",
          marginTop: "0",
        }}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default TopSection;
