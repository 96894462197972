import React from "react";
import styles from "./SearchBar.module.css";
import { FaSearch } from "react-icons/fa"; // آیکون از react-icons

interface SearchBarProps {
  placeholder?: string; // متن پیش‌فرض
  onSearch: (query: string) => void; // تابع برای جستجو
  className?: string; // امکان اضافه کردن استایل سفارشی
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "نام آرایشگر یا سرویس را جستجو کنید",
  onSearch,
  className = "",
}) => {
  return (
    <div className={`${styles.searchBar} ${className}`}>
      {/* آیکون جستجو */}
      <span className={styles.icon}>
        <FaSearch />
      </span>
      {/* ورودی */}
      <input
        type="text"
        className={styles.input}
        placeholder={placeholder}
        onChange={(e) => onSearch(e.target.value)}
        aria-label="Search"
      />
    </div>
  );
};

export default SearchBar;
