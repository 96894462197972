import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SecondPageTopSection from "../components/TopSection/SecondPageTopSection";
import BgImage from "../assets/Bg.webp";

const MobileOtpPage: React.FC = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleResendCode = () => {
    console.log("کد ارسال مجدد شد");
  };

  const handleGoBack = () => {
    navigate("/mobile-verify");
  };

  const handleContinue = () => {
    navigate("/main");
  };

  return (
    <Layout>
      <div
        style={{
          height: "100vh", // کل ارتفاع صفحه
          display: "flex",
          flexDirection: "column", // تقسیم‌بندی عمودی بخش‌ها
        }}
      >
        {/* بخش بالایی */}
        <div
          style={{
            flex: "0 0 45%", // 45% از ارتفاع برای بخش بالایی
            position: "relative",
            zIndex: 1,
          }}
        >
          <SecondPageTopSection
            backgroundImage={BgImage}
            logoImage={require("../assets/images/logo.png")}
            title="سالن زیبایی میناراد"
            description="لاین زیبایی"
            customStyles={{
              container: { height: "100%" }, // تطبیق کامل با بخش بالایی
              title: { color: "white" },
              description: { color: "white" },
            }}
          />
        </div>

        {/* بخش پایینی */}
        <div
          style={{
            flex: 1, // 55% باقی‌مانده ارتفاع برای بخش پایینی
            backgroundColor: "#FFFFFF",
            borderRadius: "50px 50px 0 0", // گوشه‌های حلال
            marginTop: "-50px", // حلال روی بخش بالا قرار بگیرد
            padding: "20px",
            overflow: "hidden", // حذف اسکرول اضافی
            zIndex: 10,
          }}
        >
          {/* آیکون پیامک */}
          <div className="text-center mb-4">
            <i
              className="bi bi-chat-dots-fill"
              style={{ fontSize: "3rem", color: "#6200EA" }}
            ></i>
          </div>

          {/* متن اصلی */}
          <h6 className="fw-bold text-center mb-3">
            لطفا کد ارسال شده را وارد کنید
          </h6>
          <p className="text-center text-secondary mb-4">
            کد به شماره <strong>09123456789</strong> ارسال شده است
          </p>

          {/* فیلد ورودی */}
          <input
            type="text"
            maxLength={4}
            className="form-control text-center fs-4 mb-4"
            placeholder="----"
            style={{
              letterSpacing: "10px",
              padding: "10px",
              backgroundColor: "#F5F5F5",
              borderRadius: "8px",
            }}
            value={code}
            onChange={handleCodeChange}
          />

          {/* لینک‌ها و تایمر */}
          <p className="text-center text-secondary mb-3">
            در انتظار دریافت کد{" "}
            <button
              className="btn btn-link text-primary p-0"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleResendCode}
            >
              ارسال مجدد
            </button>{" "}
            (02:00)
          </p>
          <p className="text-center text-primary d-flex justify-content-center align-items-center mb-4">
            <i className="bi bi-telephone-fill me-2"></i>
            اعلام کد از طریق تماس تلفنی
          </p>

          {/* دکمه ادامه */}
          <button
            className="btn btn-primary w-100 mb-3"
            style={{
              height: "50px",
              borderRadius: "24px",
              fontWeight: "bold",
              fontSize: "16px",
              backgroundColor: "#695CD5",
            }}
            onClick={handleContinue}
          >
            ادامه
          </button>

          {/* دکمه بازگشت */}
          <p className="text-center text-secondary">
            <button
              className="btn btn-link text-primary p-0"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleGoBack}
            >
              بازگشت به مرحله قبل
            </button>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default MobileOtpPage;
