import React from "react";
import { Link } from "react-router-dom"; // استفاده از Link برای ناوبری
import styles from "./ReservedTimes.module.css";
import defaultImage from "../../assets/images/freeface.jpg"; // مسیر عکس پیش‌فرض
import { ReactComponent as CartIcon } from "../../assets/icons/CartIcon.svg"; // وارد کردن آیکون جدید
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg"; // وارد کردن SVG
import dayjs from "dayjs"; // وارد کردن dayjs برای تاریخ شمسی
import "dayjs/locale/fa"; // برای پشتیبانی از تاریخ شمسی
import ClockIcon from "../../assets/icons/ClockIcon.svg";

dayjs.locale("fa"); // تنظیم زبان به فارسی برای نمایش تاریخ شمسی

interface ReservedTime {
  id: number;
  title: string;
  stylist: string;
  date: string;
  time: string;
  stylistImage: string;
  isPaid: boolean;
  start: string; // ویژگی جدید برای زمان شروع
  end: string; // ویژگی جدید برای زمان پایان
}

interface ReservedTimesProps {
  reservations: ReservedTime[];
  layout?: "vertical" | "horizontal"; // افزودن prop برای تعیین حالت نمایش
}

// تابع برای فرمت کردن تاریخ به تاریخ شمسی
const formatDate = (date: string): string => {
  return dayjs(date).format("D MMMM"); // فرمت شمسی برای روز و ماه
};

const ReservationItem: React.FC<{ reservation: ReservedTime }> = ({
  reservation,
}) => {
  const cardStyle = reservation.isPaid ? styles.card : styles.cardPending;
  const headerStyle = reservation.isPaid
    ? styles.cardHeader
    : styles.cardPendingHeader;
  const statusCircleStyle = reservation.isPaid
    ? styles.statusCircle
    : styles.statusCirclePending;
  const bodyStyle = reservation.isPaid
    ? styles.cardBody
    : styles.cardBodyPending;

  const startTime = dayjs(reservation.start, "HH:mm").toDate();
  const endTime = dayjs(reservation.end, "HH:mm").toDate();

  return (
    <div className={cardStyle}>
      {/* هدر کارت */}
      <div className={headerStyle}>
        <div className={styles.statusSection}>
          <span className={statusCircleStyle}>
            {reservation.isPaid ? "✔" : "!"}
          </span>
          <span className={styles.statusText}>
            {reservation.isPaid ? "رزرو شده" : "در انتظار پرداخت"}
          </span>
        </div>
        {/* لینک به صفحه جزئیات */}
        <Link to={`/details/${reservation.id}`} className={styles.detailsText}>
          <span className={styles.detailsLink}>جزئیات</span>
          <span className={styles.arrowIcon}>
            <ArrowIcon />
          </span>
        </Link>
      </div>

      {/* بخش اصلی کارت */}
      <div className={bodyStyle}>
        <img
          src={reservation.stylistImage || defaultImage}
          alt={reservation.stylist}
          className={styles.stylistImage}
        />
        <div className={styles.textContainer}>
          <h6 className={styles.serviceTitle}>{reservation.title}</h6>
          <p className={styles.stylistName}>{reservation.stylist}</p>
        </div>
      </div>

      {/* پایین کارت */}
      <div className={styles.cardFooter}>
        <div className={styles.timeContainer}>
          <CartIcon className={styles.timeIcon} />
          {/* نمایش تاریخ به فرمت روز و ماه */}
          <span className={styles.timeText}>
            {formatDate(reservation.date)}
          </span>
        </div>

        {/* بخش ساعت */}
        <div className={styles.timeContainer}>
          <span className={styles.timeIcon}>
            <img src={ClockIcon} alt="Clock Icon" />
          </span>

          <span className={styles.timeText}>
            {startTime instanceof Date && !isNaN(startTime.getTime())
              ? new Date(startTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false, // بدون AM/PM
                })
              : "نامعتبر"}{" تا "}
            {endTime instanceof Date && !isNaN(endTime.getTime())
              ? new Date(endTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false, // بدون AM/PM
                })
              : "نامعتبر"}
          </span>
        </div>
      </div>
    </div>
  );
};

const ReservedTimes: React.FC<ReservedTimesProps> = ({ reservations, layout = "horizontal" }) => {
  if (reservations.length === 0) {
    return (
      <div className={styles.emptyContainer}>
        <h6 className={styles.title}>وقت‌های رزرو شده شما</h6>
        <p>شما هنوز وقتی رزرو نکرده‌اید.</p>
      </div>
    );
  }

  return (
    <div>
      <h6 className={styles.title}>وقت‌های رزرو شده شما</h6>
      <div className={layout === "horizontal" ? styles.horizontalScroll : styles.verticalScroll}>
        {reservations.map((reservation) => (
          <ReservationItem key={reservation.id} reservation={reservation} />
        ))}
      </div>
    </div>
  );
};

export default ReservedTimes;
