import React from "react";
import { useNavigate } from "react-router-dom"; // اضافه کردن useNavigate
import styles from "./AvilableServices.module.css";
import defaultImage from "../../assets/images/freeface.jpg"; // مسیر عکس پیش‌فرض
import clockIcon from "../../assets/icons/ClockIcon.svg"; // مسیر آیکون ساعت

interface ReservedTime {
  id: number;
  title: string;
  stylist: string;
  date: string;
  stylistImage: string;
  isPaid: boolean;
  services: string[];
  icon: string;
  start: string; // ساعت شروع
  end: string; // ساعت پایان
}

interface AvilableServicesProps {
  reservations: ReservedTime[];
  layout?: "vertical" | "horizontal"; // افزودن prop برای تعیین حالت نمایش
}

const ReservationItem: React.FC<{ reservation: ReservedTime }> = ({
  reservation,
}) => {
  const navigate = useNavigate(); // تعریف navigate

  const handleReservationClick = () => {
    navigate("/service-details", { state: { service: reservation.title, icon: reservation.icon } }) // ارسال اطلاعات رزرو به صفحه مقصد
  };

  
  return (
    <div className={styles.card}>
      {/* بخش اصلی کارت */}
      <div className={styles.cardBody}>
        <img
          src={reservation.stylistImage || defaultImage}
          alt={reservation.stylist}
          className={styles.stylistImage}
        />
        <div className={styles.textContainer}>
          {/* اضافه کردن آیکون و متن در یک ردیف */}
          <div className={styles.iconTextContainer}>
            <img
              src={reservation.icon}
              alt="Service Icon"
              className={styles.iconImage}
            />
            <h6 className={styles.serviceTitle}>{reservation.title}</h6>
          </div>
          <p className={styles.stylistName}>{reservation.stylist}</p>
        </div>
      </div>

      {/* پایین کارت */}
      <div className={styles.cardFooter}>
        {/* دکمه رزرو وقت */}
        <div className={styles.reserveButton} onClick={handleReservationClick}>
          <span className={styles.reserveArrow}>&lt;</span>
          رزرو وقت
        </div>

        {/* نمایش ساعت رزرو */}
        <div className={styles.timeContainer}>
          <span className={styles.timeText}>
            {reservation.start} - {reservation.end} {/* ساعت شروع و پایان */}
          </span>
          <img
            src={clockIcon} // استفاده از آیکون ساعت
            alt="Clock"
            className={styles.clockIcon}
          />
        </div>
      </div>
    </div>
  );
};

const AvilableServices: React.FC<AvilableServicesProps> = ({
  reservations,
  layout = "horizontal",
}) => {
  if (reservations.length === 0) {
    return (
      <div className={styles.emptyContainer}>
        <p>شما هنوز وقتی رزرو نکرده‌اید.</p>
      </div>
    );
  }

  return (
    <div>
      <div
        className={
          layout === "horizontal"
            ? styles.horizontalScroll
            : styles.verticalScroll
        }
      >
        {reservations.map((reservation) => (
          <ReservationItem key={reservation.id} reservation={reservation} />
        ))}
      </div>
    </div>
  );
};

export default AvilableServices;
