import React from "react";

interface InfoBoxProps {
  description?: string; // توضیحات اختیاری
  steps: string[]; // مراحل
  buttonText: string; // متن دکمه
  onButtonClick: () => void; // عملکرد دکمه
}

const InfoBox: React.FC<InfoBoxProps> = ({
  description,
  steps,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div
      className="border p-4 d-flex flex-column align-items-center mx-3"
      style={{
        borderRadius: "25px", // گوشه‌های نرم‌تر
        direction: "rtl", // راست‌چین
        marginTop: "-20px", // بالا بردن کل بخش
      }}
    >
      {/* توضیحات (در صورت وجود) */}
      {description && (
        <p
          className="text-dark fw-semibold mb-3 text-end"
          style={{
            fontSize: "1rem",
            color: "#4a4a4a", // رنگ متن
          }}
        >
          {description}
        </p>
      )}

      {/* لیست مراحل */}
      <ul
        className="list-unstyled w-100 text-end d-flex flex-column gap-2"
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        {steps.map((step, index) => (
          <li
            key={index}
            className="d-flex align-items-center"
            style={{
              fontSize: "0.9rem",
              color: "#4a4a4a", // رنگ مراحل
            }}
          >
            {step}
          </li>
        ))}
      </ul>

      {/* دکمه */}
      <div className="w-100 mt-4">
        <button
          className="btn btn-primary w-100"
          style={{
            height: "47px",
            borderRadius: "24px",
            fontSize: "16px",
            fontWeight: "bold",
            backgroundColor: "#695CD5",
            border: "none",
          }}
          onClick={onButtonClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default InfoBox;
