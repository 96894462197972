import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import NavigationSection from "../components/NavigationMenu/NavigationMenu";
import AvilableServices from "../components/Services/AvilableServices";
import DateNavigation from "../components/TopSection/DateNavigation";
import FilterSection from "../components/Services/FilterSection";
import styles from "./TimesPage.module.css"; // وارد کردن استایل‌ها
import { utils, Day } from "react-modern-calendar-datepicker";
import HairDryerIcon from "../assets/icons/hair_dryer.svg";
import MakeupIcon from "../assets/icons/makeup_kit.svg";
import ScissorsIcon from "../assets/icons/scissors.svg";

const TimesPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const serviceName = searchParams.get("service");
  const stylistName = searchParams.get("stylist");

  // داده‌های نمونه برای رزرو
  const reservations = [
    {
      id: 1,
      title: "وقت رنگ و لایت مو",
      stylist: "شادی رضایی",
      date: "2023-12-01",
      time: "18:00 تا 19:00",
      stylistImage: "",
      isPaid: true,
      start: "18:00",
      end: "19:00",
      services: ["رنگ و لایت", "براشینگ"],
      icon: HairDryerIcon,
    },
    {
      id: 2,
      title: "وقت براشینگ",
      stylist: "شادی رضایی",
      date: "2023-12-01",
      time: "19:00 تا 20:00",
      stylistImage: "",
      isPaid: false,
      start: "19:00",
      end: "20:00",
      services: ["براشینگ"],
      icon: ScissorsIcon,
    },
    {
      id: 3,
      title: "وقت آرایش",
      stylist: "مریم احمدی",
      date: "2023-12-02",
      time: "10:00 تا 11:00",
      stylistImage: "",
      isPaid: true,
      start: "10:00",
      end: "11:00",
      services: ["آرایش", "کوتاهی مو"],
      icon: MakeupIcon,
    },
  ];

  const hairdressers = [
    "شادی رضایی",
    "مریم احمدی",
    "زهرا کریمی",
    "ناهید محمدی",
  ];
  const services = ["رنگ و لایت", "براشینگ", "آرایش", "کوتاهی مو"];

  // وضعیت برای فیلتر کردن
  const [filteredStylist, setFilteredStylist] = useState<string | null>(
    stylistName || null
  );
  const [filteredService, setFilteredService] = useState<string | null>(
    serviceName || null
  );
  const [selectedDate, setSelectedDate] = useState<Day | null>(null); // تاریخ انتخاب‌شده
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterChange = (
    stylist: string | null,
    service: string | null
  ) => {
    setFilteredStylist(stylist);
    setFilteredService(service);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleDateChange = (date: Day) => {
    setSelectedDate(date); // تنظیم تاریخ انتخاب‌شده
  };

  // فیلتر کردن رزروها
  const filteredReservations = reservations.filter((reservation) => {
    const matchesService = filteredService
      ? reservation.services.includes(filteredService)
      : true;
    const matchesStylist = filteredStylist
      ? reservation.stylist === filteredStylist
      : true;
    return matchesService && matchesStylist;
  });

  return (
    <Layout>
      {/* تاریخ و جهت‌نما */}
      <div className={styles.dateNavigation}>
        <DateNavigation onDateChange={handleDateChange} />
      </div>

      <section className={styles.timesPageSection}>
        {/* بخش فیلتر و عنوان وقت‌ها */}
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <button
            onClick={handleFilterClick}
            className={`${styles.filterButton} d-flex align-items-center justify-content-center`}
          >
            <span className={styles.filterButtonText}>فیلتر</span>
            <div className={styles.filterButtonIcon}>
              <div></div>
              <div></div>
            </div>
          </button>

          <h6 className="fw-bold" style={{ marginRight: "24px" }}>
            وقت‌های{" "}
            {selectedDate
              ? `${selectedDate.day} ${utils("fa").getMonthName(
                  selectedDate.month
                )} ${selectedDate.year}`
              : filteredService
              ? filteredService
              : "همه سرویس‌ها"}
          </h6>
        </div>

        {/* نمایش بخش فیلتر شده */}
        <div className="mb-2">
          <div className={`${styles.filtersContainer}`}>
            {filteredService && (
              <div className={`${styles.filterInfoBox}`}>
                <p className="fs-7 text-dark mb-0">{filteredService}</p>
                <button
                  onClick={() => setFilteredService(null)}
                  className={`${styles.removeFilterButton}`}
                  aria-label="حذف فیلتر سرویس"
                >
                  ×
                </button>
              </div>
            )}

            {filteredStylist && (
              <div className={`${styles.filterInfoBox}`}>
                <p className="fs-7 mb-0">{filteredStylist}</p>
                <button
                  onClick={() => setFilteredStylist(null)}
                  className={`${styles.removeFilterButton}`}
                  aria-label="حذف فیلتر آرایشگر"
                >
                  ×
                </button>
              </div>
            )}
          </div>
        </div>

        {/* بخش فیلتر باز */}
        {isFilterOpen && (
          <div className="mb-2">
            <FilterSection
              onFilterChange={handleFilterChange}
              services={services}
              hairdressers={hairdressers}
              selectedStylist={filteredStylist}
              selectedService={filteredService}
            />
          </div>
        )}

        {/* لیست رزروها */}
        <div className="d-flex justify-content-center">
          <AvilableServices
            reservations={filteredReservations}
            layout="vertical"
          />
        </div>
      </section>

      <div className="mb-2">
        <NavigationSection />
      </div>
    </Layout>
  );
};

export default TimesPage;
