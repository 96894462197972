import React from "react";
import styles from "./DescriptionSection.module.css";

const DescriptionSection: React.FC = () => {
  return (
    <div className={styles.descriptionContainer}>
      <h3 className={styles.title}>:توضیحات</h3>
      <ul className={styles.descriptionList}>
        <li>مراجعین گرامی لطفا توجه داشته باشید حضور به موقع شما در سالن به منظور رعایت نظم نوبت‌ها و عدم معطلی بسیار مهم است.</li>
        <li>دریافت پیامک حاوی تاریخ و زمان مراجعه به سالن به منزله رزرو نوبت شما است.</li>
      </ul>
    </div>
  );
};

export default DescriptionSection;
