import React, { useState, useEffect, useMemo, useCallback } from "react";
import { utils, Day } from "react-modern-calendar-datepicker";
import "./DateNavigation.css";

interface MonthDayInfo {
  date: Day;
  dayOfWeek: string;
  isToday: boolean;
  isSelected: boolean;
}

interface DateNavigationProps {
  onDateChange: (selectedDate: Day) => void;
}

const DateNavigation: React.FC<DateNavigationProps> = ({ onDateChange }) => {
  const today = useMemo(() => utils("fa").getToday(), []);
  const daysOfWeek = useMemo(
    () => [
      "شنبه",
      "یک‌شنبه",
      "دوشنبه",
      "سه‌شنبه",
      "چهارشنبه",
      "پنج‌شنبه",
      "جمعه",
    ],
    []
  );

  const generateMonthDays = useCallback(
    (referenceDate: Day): MonthDayInfo[] => {
      const endOfMonth = new Date(referenceDate.year, referenceDate.month, 0);
      const daysInMonth = endOfMonth.getDate();

      return Array.from({ length: daysInMonth }, (_, dayOffset) => {
        const offsetDate = new Date(
          referenceDate.year,
          referenceDate.month - 1,
          dayOffset + 1
        );
        const date: Day = {
          year: offsetDate.getFullYear(),
          month: offsetDate.getMonth() + 1,
          day: offsetDate.getDate(),
        };
        const dayOfWeek = daysOfWeek[(offsetDate.getDay() + 1) % 7];

        return {
          date,
          dayOfWeek,
          isToday:
            date.day === today.day &&
            date.month === today.month &&
            date.year === today.year,
          isSelected:
            date.day === referenceDate.day &&
            date.month === referenceDate.month &&
            date.year === referenceDate.year,
        };
      }).filter(
        (dayInfo) =>
          dayInfo.date.year > today.year ||
          (dayInfo.date.year === today.year &&
            (dayInfo.date.month > today.month ||
              (dayInfo.date.month === today.month &&
                dayInfo.date.day >= today.day)))
      );
    },
    [daysOfWeek, today]
  );

  const [state, setState] = useState(() => ({
    selectedDate: today,
    monthDays: generateMonthDays(today),
  }));

  useEffect(() => {
    onDateChange(state.selectedDate);
  }, [onDateChange, state.selectedDate]);

  const handleMonthChange = useCallback(
    (direction: "prev" | "next") => {
      setState((prevState) => {
        const current = new Date(
          prevState.selectedDate.year,
          prevState.selectedDate.month - 1,
          1
        );
        const newDate = new Date(
          current.setMonth(current.getMonth() + (direction === "next" ? 1 : -1))
        );

        // جلوگیری از رفتن به ماه قبل از امروز
        if (
          direction === "prev" &&
          (newDate.getFullYear() < today.year ||
            (newDate.getFullYear() === today.year &&
              newDate.getMonth() + 1 < today.month))
        ) {
          return prevState;
        }

        return {
          ...prevState,
          selectedDate: {
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: 1,
          },
          monthDays: generateMonthDays({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: 1,
          }),
        };
      });
    },
    [generateMonthDays, today]
  );

  const handleDateSelect = useCallback(
    (date: Day) => {
      // جلوگیری از انتخاب تاریخی قبل از امروز
      if (
        date.year < today.year ||
        (date.year === today.year &&
          (date.month < today.month ||
            (date.month === today.month && date.day < today.day)))
      ) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        selectedDate: date,
        monthDays: prevState.monthDays.map((dayInfo) => ({
          ...dayInfo,
          isSelected:
            dayInfo.date.year === date.year &&
            dayInfo.date.month === date.month &&
            dayInfo.date.day === date.day,
        })),
      }));
      onDateChange(date);
    },
    [onDateChange, today]
  );

  return (
    <div className="date-navigation-wrapper">
      <div className="date-navigation-header">
        <button
          className="month-button"
          onClick={() => handleMonthChange("prev")}
        >
          <span className="arrow-right"></span> ماه قبل
        </button>
        <h6 className="current-month">
          {utils("fa").getMonthName(state.selectedDate.month)}{" "}
          {state.selectedDate.year}
        </h6>
        <button
          className="month-button"
          onClick={() => handleMonthChange("next")}
        >
          ماه بعد <span className="arrow-left"></span>
        </button>
      </div>
      <div className="date-navigation-days">
        {state.monthDays.map((dayInfo) => (
          <div
            key={`${dayInfo.date.year}-${dayInfo.date.month}-${dayInfo.date.day}`}
            className={`day-item ${
              dayInfo.isSelected ? "selected" : dayInfo.isToday ? "active" : ""
            }`}
            onClick={() => handleDateSelect(dayInfo.date)}
          >
            <div className="day-name">{dayInfo.dayOfWeek}</div>
            <div className="day-number">{dayInfo.date.day}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(DateNavigation);
