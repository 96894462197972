import React from "react";
import styles from "./GallerySection.module.css";

const images = [
  "image1.jpg",
  "image2.jpg",
  "image3.jpg",
  "image4.jpg",
  "image5.jpg",
  "image6.jpg",
  "image7.jpg",
  "image8.jpg",
  "image9.jpg",
  "image10.jpg",
];

const GallerySection: React.FC = () => {
  return (
    <div className={styles.galleryContainer}>
      <h3 className={styles.title}>نمونه کارها</h3>
      <div className={styles.gallery}>
        {images.map((image, index) => (
          <div key={index} className={styles.imageContainer}>
            <img src={image} alt={`Sample ${index + 1}`} className={styles.image} />
          </div>
        ))}
      </div>
      {/* خط افقی در پایین */}
      <div className={styles.divider}></div>
    </div>
  );
};

export default GallerySection;
