import React from "react";
import "./Layout.css";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="panel-layout">
      {/* محتوای اسکرول‌شونده */}
      <div className="panel-content">{children}</div>
    </div>
  );
};

export default Layout;
