import React from "react";
import defaultProfileImage from "../../assets/images/freeface.jpg";
import styles from "./ProfileImage.module.css";

interface ProfileImageProps {
  image?: string;
  altText?: string;
  size?: number;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
                                                     image = defaultProfileImage,
                                                     altText = "Profile Image",
                                                     size = 120,
                                                   }) => {
  const adjustedSize = size - 10; // کاهش 10 پیکسل برای حاشیه
  return (
      <div
          className={styles.container}
          style={{
            width: `${size}px`,
            height: `${size}px`,
          }}
      >
        <img
            src={image}
            alt={altText}
            className={styles.image}
            style={{
              width: `${adjustedSize}px`,
              height: `${adjustedSize}px`,
            }}
        />
      </div>
  );
};

export default ProfileImage;
