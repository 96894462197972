// src/components/HairdresserList/HairdresserList.tsx

import React from "react";
import ProfileImage from "./ProfileImage";
import HairdresserCard from "./HairdresserCard";
import styles from "./HairdresserList.module.css";
import { Link } from "react-router-dom";

interface Hairdresser {
  id: number;
  name: string;
  title: string;
  availability: string;
  image?: string; // تصویر اختیاری
  services: string[];
}

interface HairdresserListProps {
  searchQuery: string;
}

const HairdresserList: React.FC<HairdresserListProps> = ({ searchQuery }) => {
  const hairdressers: Hairdresser[] = [
    {
      id: 1,
      name: "اکرم تهرانی",
      title: "رنگ و لایت",
      availability: "۲۴ اردیبهشت",
      services: ["رنگ و لایت", "براشینگ"],
      image: require("../../assets/images/freeface.jpg"),
    },
    {
      id: 2,
      name: "شادی رضایی",
      title: "آرایش",
      availability: "۲۵ اردیبهشت",
      services: ["آرایش", "کوتاهی مو"],
      image: require("../../assets/images/freeface.jpg"),
    },
    {
      id: 3,
      name: "زهرا کریمی",
      title: "کوتاهی مو",
      availability: "۲۶ اردیبهشت",
      services: ["کوتاهی مو"],
    },
    {
      id: 4,
      name: "ناهید محمدی",
      title: "براشینگ",
      availability: "۲۷ اردیبهشت",
      services: ["براشینگ"],
    },
    {
      id: 5,
      name: "آرزو نیکوکار",
      title: "دکلره و رنگ",
      availability: "۲۸ اردیبهشت",
      services: ["دکلره", "رنگ"],
      image: require("../../assets/images/freeface.jpg"),
    },
    {
      id: 6,
      name: "مریم صفایی",
      title: "شینیون",
      availability: "۲۹ اردیبهشت",
      services: ["شینیون", "آرایش عروس"],
      image: require("../../assets/images/freeface.jpg"),
    },
    {
      id: 7,
      name: "سیمین احمدی",
      title: "رنگ و هایلایت",
      availability: "۳۰ اردیبهشت",
      services: ["رنگ", "هایلایت"],
      image: require("../../assets/images/freeface.jpg"),
    },
    {
      id: 8,
      name: "لیلا طاهری",
      title: "کوتاهی و مش",
      availability: "۳۱ اردیبهشت",
      services: ["کوتاهی مو", "مش"],
      image: require("../../assets/images/freeface.jpg"),
    },
  ];
  

  const filteredHairdressers = hairdressers.filter(
    ({ name, title }) =>
      name.includes(searchQuery) || title.includes(searchQuery)
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button
          className="btn btn-link text-decoration-none fw-bold p-0"
          onClick={() => console.log("Navigating to all hairdressers")}
          type="button"
          style={{ color: "#513DD3" }}
        >
          مشاهده همه
        </button>

        <h5 className="text-end">آرایشگرها</h5>
      </header>

      <div className={`${styles.scrollContainer} ${styles.customScrollbar}`}>
        {filteredHairdressers.map(({ id, image, title, name, availability }) => (
          <div key={id} className={styles.card}>
            <ProfileImage image={image} size={68} />
            <Link
              to={`/times?stylist=${name}`}  // پارامتر فقط آرایشگر
              className="text-decoration-none"
            >
              <HairdresserCard
                title={title}
                name={name}
                availability={availability}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HairdresserList;
