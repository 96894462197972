import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SplashPage from "./pages/splash-page";

import ServiceDetailsPage from "./pages/service-details";
import HelpPage from "./pages/help-page"; // تغییر MainPage به HelpPage
import MobileVerifyPage from "./pages/mobile-verify-page";
import MobileOtpPage from "./pages/mobile-otp-page";
import MainPage from "./pages/main-page";
// import BlockNavigationHandler from "./core/BlockNavigationHandler";
// import PortraitHandler from "./core/PortraitHandler";
// import InstallerHandler from "./core/InstallerHandler";
import TimesPage from "./pages/times-page";
import ThemeColorHandler from "./core/ThemeColorHandler";
import ServiceList from "./pages/Service-List"; // اضافه کردن ServiceListPage

const App: React.FC = () => {

    return (
        <Router>
            <ThemeColorHandler />
            <Routes>
                <Route path="/" element={<SplashPage />} /> {/* تغییر MainPage به HelpPage */}
                <Route path="/help" element={<HelpPage />} />
                <Route path="/mobile-verify" element={<MobileVerifyPage />} />
                <Route path="/verify-otp" element={<MobileOtpPage />} />
                <Route path="/main" element={<MainPage />} />
                <Route path="/times" element={<TimesPage />} />
                <Route path="/service-details" element={<ServiceDetailsPage />} />
                <Route path="/ServiceList" element={<ServiceList />} /> {/* مسیر جدید */}
            </Routes>
        </Router>
    );
};

export default App;
