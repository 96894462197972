import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ServiceItem from "./ServiceItem";
import styles from "./Services.module.css";

interface ServicesProps {
  searchQuery: string;
}

const Services: React.FC<ServicesProps> = ({ searchQuery }) => {
  const navigate = useNavigate();

  // دریافت سرویس‌ها از Redux
  const services = useSelector((state: RootState) => state.services.services);

  // فیلتر کردن بر اساس Query
  const filteredServices = services.filter((service) =>
    service.name.includes(searchQuery)
  );

  return (
    <section className="services-section" style={{ marginTop: "24px" }}>
      <div
        className="d-flex justify-content-between align-items-center mb-1"
        style={{
          padding: "0 15px",
          width: "99%",
        }}
      >
        <button
          onClick={() => navigate("/ServiceList")}
          className="btn btn-link fw-bold"
          style={{
            textDecoration: "none",
            background: "none",
            border: "none",
            padding: "0",
            cursor: "pointer",
            color: "#513DD3",
          }}
        >
          مشاهده همه
        </button>

        <h6 className="text-dark fw-bold">سرویس‌ها</h6>
      </div>

      <div
        className={`d-flex overflow-auto ${styles["custom-scrollbar"]}`}
        style={{
          gap: "5px",
          padding: "0",
          margin: "0",
          width: "97%",
          direction: "rtl",
        }}
      >
        <div className="d-flex flex-column" style={{ gap: "16px" }}>
          <div className="d-flex" style={{ gap: "10px" }}>
            {filteredServices
              .slice(0, Math.ceil(filteredServices.length / 2))
              .map((service) => (
                <ServiceItem
                  key={service.id}
                  name={service.name}
                  icon={service.icon}
                />
              ))}
          </div>

          <div className="d-flex" style={{ gap: "10px" }}>
            {filteredServices
              .slice(Math.ceil(filteredServices.length / 2))
              .map((service) => (
                <ServiceItem
                  key={service.id}
                  name={service.name}
                  icon={service.icon}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
