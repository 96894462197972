import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SecondPageTopSection from "../components/TopSection/SecondPageTopSection";
import ContactSection from "../components/ContactSection/ContactSection";
import BgImage from "../assets/Bg.webp";

const MobileVerifyPage: React.FC = () => {
  const navigate = useNavigate();

  const handlePhoneInputChange = (value: string) => {
    console.log("Phone number:", value);
  };

  const handleContinue = () => {
    navigate("/verify-otp");
  };

  return (
    <Layout>
      <div
        style={{
          height: "100vh", // کل ارتفاع صفحه
          display: "flex",
          flexDirection: "column", // تقسیم عمودی محتوا
        }}
      >
        {/* بخش بالایی */}
        <div
          style={{
            flex: "0 0 55%", // 45% از ارتفاع صفحه
            position: "relative",
            zIndex: 1,
          }}
        >
          <SecondPageTopSection
            backgroundImage={BgImage}
            logoImage={require("../assets/images/logo.png")}
            title="سالن زیبایی میناراد"
            description="لاین زیبایی"
            customStyles={{
              container: { height: "100%" }, // تطبیق با ارتفاع بخش بالایی
            }}
          />
        </div>

        {/* بخش پایینی */}
        <div
          style={{
            flex: 1, // باقی‌مانده ارتفاع (55%)
            backgroundColor: "#FFFFFF",
            borderRadius: "50px 50px 0 0", // گوشه‌های حلال
            marginTop: "-40px", // بخش پایینی روی بالایی بیاید
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // محتوا را عمودی وسط چیده می‌شود
            alignItems: "center", // محتوا را افقی وسط چیده می‌شود
            zIndex: 10,
          }}
        >
          <p
            style={{
              color: "#555",
              marginBottom: "10px",
              fontSize: "16px",
              textAlign: "center", // متن در وسط افقی
              width: "100%",
            }}
          >
            .لطفا برای ادامه شماره تلفن خود را وارد کنید
          </p>

          {/* فیلد ورودی شماره تلفن */}
          <input
            type="text"
            placeholder="09*********"
            className="form-control"
            style={{
              width: "90%",
              height: "50px",
              textAlign: "center",
              fontSize: "16px",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
            onChange={(e) => handlePhoneInputChange(e.target.value)}
          />

          {/* دکمه ادامه */}
          <button
            className="btn btn-primary"
            style={{
              width: "90%",
              height: "50px",
              borderRadius: "24px",
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#695CD5",
            }}
            onClick={handleContinue}
          >
            ادامه
          </button>

          {/* خط جداکننده */}
          <hr
            style={{
              margin: "24px auto",
              width: "70%",
              borderColor: "#ccc",
              opacity: 0.6,
            }}
          />

          <ContactSection />
        </div>
      </div>
    </Layout>
  );
};

export default MobileVerifyPage;
