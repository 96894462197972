import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Layout from "../components/Layout/Layout";
import styles from "./ServiceList.module.css";
import ArrowRightIcon from "../assets/icons/arrowRight.svg";
import ArrowLeftIcon from "../assets/icons/arrowLeft.svg"; // آیکون برگشت
import PolygonIcon from "../assets/icons/Polygon.svg"; // استفاده از آیکون Polygon از مسیر قبلی

interface Service {
  id: number;
  name: string;
  icon: string;
}

const ServiceCard: React.FC<{ service: Service }> = React.memo(
  ({ service }) => (
    <div key={service.id} className={styles.serviceCard}>
      <div className={styles.serviceRow}>
        <div className={styles.serviceInfo}>
          <div className={styles.serviceHeader}>
            <h6 className={styles.serviceName}>{service.name}</h6>
            <img
              src={PolygonIcon} // استفاده از PolygonIcon
              alt="Polygon Icon"
              className={styles.servicePolygonIcon}
            />
          </div>
          <p className={styles.serviceDetails}>
            محبوبه اکبری، کنار شاکری ادی رضایی، محبوبه اکبری، کنار شاکری
          </p>
        </div>

        <div className={styles.serviceIconContainer}>
          <img
            src={service.icon}
            alt={service.name}
            className={styles.serviceIcon}
          />
        </div>
      </div>

      <div className={styles.reserveButtonContainer}>
        <button className={styles.reserveButton}>
          <img
            src={ArrowRightIcon}
            alt="Arrow Right"
            className={styles.reserveArrowIcon}
          />
          <span>رزرو نوبت</span>
        </button>
      </div>
    </div>
  )
);

const ServiceList: React.FC = () => {
  const services = useSelector((state: RootState) => state.services.services);

  return (
    <Layout>
      <div className={`${styles.serviceListPage} container px-3`}>
        <div className={styles.serviceListHeader}>
          <h6 className="m-0 fw-bold">سرویس‌ها</h6>
          <button className="btn p-0" onClick={() => window.history.back()}>
            <img
              src={ArrowLeftIcon} // آیکون برگشت
              alt="Back Arrow"
              width="24"
              height="24"
              className="arrow-right" // اضافه کردن کلاس برای چرخاندن آیکون
            />
          </button>
        </div>

        <div className={styles.serviceListContent}>
          {services.map((service: Service) => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ServiceList;
