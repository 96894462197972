// theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'IRANSansX', sans-serif", // فونت پیش‌فرض برای تمام متن‌ها
    h1: {
      fontFamily: "'IRANSansX', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "'IRANSansX', sans-serif",
      fontWeight: 500,
    },
    body1: {
      fontFamily: "'IRANSansX', sans-serif",
    },
    button: {
      fontFamily: "'IRANSansX', sans-serif",
      fontWeight: 700,
    },
  },
});

export default theme;
