// src/store/tabSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
  activeTab: 'home' | 'appointments' | 'profile';
  selectedService: string | null;
  selectedStylist: string | null; // پارامتر جدید برای آرایشگر انتخاب شده
}

// اصلاح در مقدار اولیه activeTab برای جلوگیری از خواندن از null
const initialState: TabState = {
  activeTab: (localStorage.getItem('activeTab') as 'home' | 'appointments' | 'profile') || 'home', // مقدار پیش‌فرض به‌درستی برای activeTab
  selectedService: null,
  selectedStylist: null, // مقدار اولیه null
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<'home' | 'appointments' | 'profile'>) {
      state.activeTab = action.payload;
      localStorage.setItem('activeTab', action.payload); // ذخیره تغییرات در localStorage
    },
    setSelectedService(state, action: PayloadAction<string | null>) {
      state.selectedService = action.payload;
    },
    setSelectedStylist(state, action: PayloadAction<string | null>) {
      state.selectedStylist = action.payload;
    },
  },
});

export const { setActiveTab, setSelectedService, setSelectedStylist } = tabSlice.actions;
export default tabSlice.reducer;
