import React from "react";
import "./SecondPageTopSection.css"; // وارد کردن استایل‌ها

interface SecondPageTopSectionProps {
  backgroundImage: string;
  logoImage: string;
  title: string;
  description: string;
  customStyles?: {
    container?: React.CSSProperties;
    title?: React.CSSProperties;
    description?: React.CSSProperties;
    avatar?: React.CSSProperties;
    content?: {
      bottom?: number;
      right?: number;
    };
  };
}

const SecondPageTopSection: React.FC<SecondPageTopSectionProps> = ({
  logoImage,
  title,
  description,
  customStyles = {},
}) => {
  return (
    <div
      className="second-page-top-section d-flex flex-column"
      style={customStyles.container}
    >
      {/* پس‌زمینه رنگی */}
      <div
        className="second-page-top-section-background"
        style={customStyles?.container}
      ></div>

      {/* محتوای بخش */}
      <div
        className="second-page-top-section-content"
        style={customStyles.content}
      >
        {/* لوگو */}
        <div
          className="second-page-top-section-logo"
          style={{
            backgroundImage: `url(${logoImage})`,
            ...customStyles.avatar,
          }}
        ></div>

        {/* عنوان */}
        <h3
          className="second-page-top-section-title"
          style={customStyles.title}
        >
          {title}
        </h3>

        {/* توضیحات */}
        <h6
          className="second-page-top-section-description"
          style={customStyles.description}
        >
          {description}
        </h6>
      </div>
    </div>
  );
};

export default SecondPageTopSection;
