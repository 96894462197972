import React from "react";
import SearchBar from "./SearchBar";
import Logo from "../../assets/images/logo.png";
import styles from "./Header.module.css"; // استایل جداگانه

interface HeaderProps {
  onSearch: (query: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  return (
    <div className={`shadow-sm py-4 px-4 ${styles.headerContainer}`}>
      {/* لوگو و متن خوش‌آمدگویی */}
      <div className="d-flex align-items-center justify-content-end">
        {/* متن خوش‌آمدگویی */}
        <div className={`me-2 text-end ${styles.welcomeText}`}>
          <h5 className="fw-bold text-dark mb-1">سالن زیبایی مینا راد</h5>
          <p className="text-secondary mb-0">خوش آمدید</p>
        </div>

        {/* لوگو */}
        <div className={styles.logoWrapper}>
          <img src={Logo} alt="لوگو" className={styles.logo} />
        </div>
      </div>

      {/* فیلد جستجو */}
      <div className={`mt-3 mx-auto ${styles.searchContainer}`}>
        <SearchBar onSearch={onSearch} />
      </div>
    </div>
  );
};

export default Header;
