import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // استایل‌های اصلی شامل فونت‌ها
import App from "./App";
import theme from "./theme"; // تم سفارشی Material-UI
import { ThemeProvider } from "@mui/material/styles"; // ایمپورت ThemeProvider از Material-UI
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { Provider } from 'react-redux';
import store from './store'; // Store Redux شما

// ایجاد ریشه برای React
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// رندر کردن اپلیکیشن به همراه ThemeProvider و Provider
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// ثبت Service Worker برای حالت PWA
serviceWorkerRegistration.unregister(); // می‌توانید آن را به register() تغییر دهید

// ابزار گزارش‌دهی عملکرد
reportWebVitals();
