import React from "react";
import styles from "./PaymentStatus.module.css";
import YelloAlert from "../../assets/icons/YelloAlert.svg"; // ایمپورت آیکون

const PaymentStatus: React.FC = () => {
  return (
    <div className={styles.paymentContainer}>
      <div className={styles.statusBox}>
        <div className={styles.statusRow}>
          <p className={styles.statusText}>در انتظار پرداخت</p>
          <img
            src={YelloAlert}
            alt="هشدار"
            className={styles.warningIcon} // استفاده از کلاس استایل برای آیکون
          />
        </div>

        <div className={styles.amountRow}>
          <p className={styles.amountText}> تومان  5000 </p>
          <p className={styles.amountLabel}>مبلغ قابل پرداخت</p>
        </div>
      </div>
      <button className={styles.payButton}>رزرو وقت و پرداخت</button>
    </div>
  );
};

export default PaymentStatus;
