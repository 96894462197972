import React from "react";
import TopServiceHeader from "../components/TopSection/TopServiceHeader";
import DetailsSection from "../components/InfoBox/DetailsSection";
import GallerySection from "../components/Gallery/GallerySection";
import SelectedServiceSection from "../components/Services/SelectedServiceSection";
import PaymentStatus from "../components/Reserved/PaymentStatus";
import Layout from "../components/Layout/Layout";
import DescriptionSection from "../components/Description/DescriptionSection"; // اضافه کردن DescriptionSection
import styles from "./Service-Details.module.css";

const ServiceDetails: React.FC = () => {
  return (
    <Layout>
      <div className={styles.container}>
        {/* بخش اسکرول‌پذیر */}
        <div className={styles.scrollableContent}>
          {/* سربرگ */}
          <TopServiceHeader />

          {/* جزئیات تاریخ و آدرس */}
          <DetailsSection />

          {/* نمونه کارها */}
          <GallerySection />

          {/* سرویس انتخاب‌شده */}
          <SelectedServiceSection />

          {/* توضیحات */}
          <DescriptionSection />
        </div>

        {/* وضعیت پرداخت (ثابت در پایین) */}
        <PaymentStatus />
      </div>
    </Layout>
  );
};

export default ServiceDetails;
