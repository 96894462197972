import React from "react";
import styles from "./DetailsSection.module.css";
import calendarIcon from "../../assets/icons/Calendar2.svg"; // آیکون تقویم
import locationIcon from "../../assets/icons/addres.svg"; // آیکون مکان
import phoneIcon from "../../assets/icons/Call.svg"; // آیکون تلفن

const DetailsSection: React.FC = () => {
  return (
    <div className={styles.detailsContainer}>
      {/* تاریخ و ساعت */}
      <div className={styles.row}>
        <img src={calendarIcon} alt="Calendar2" className={styles.icon} />
        <div>
          <p className={styles.label}>:تاریخ و ساعت وقت</p>
          <p className={styles.value}>9 اردیبهشت - (امروز) ساعت 12:45</p>
        </div>
      </div>

      {/* آدرس */}
      <div className={styles.row}>
        <img src={locationIcon} alt="addres" className={styles.icon} />
        <div>
          <p className={styles.label}>:آدرس</p>
          <p className={styles.value}>
            خیابان مطهری، بلوار سعیدی، کوچه 5، طبقه دوم، پلاک 3
          </p>
        </div>
      </div>

      {/* تلفن */}
      <div className={styles.row}>
        <img src={phoneIcon} alt="Call" className={styles.icon} />
        <div>
          <p className={styles.label}>:تلفن</p>
          <p className={styles.value}>09123456789</p>
        </div>
      </div>

      {/* خط افقی در انتها */}
      <div className={styles.divider}></div>
    </div>
  );
};

export default DetailsSection;
