import React, { useEffect } from "react";
import {  useLocation } from "react-router-dom";

// Utility function to update theme color
const updateThemeColor = (color: string): void => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]") as HTMLMetaElement;
    if (metaThemeColor) {
        metaThemeColor.setAttribute("content", color);
    }
};

// Component to handle theme-color updates
const ThemeColorHandler: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        // Update theme color based on the current route
        switch (location.pathname) {
            case "/":
                updateThemeColor("#695CD5");
                break;
            case "/mobile-verify":
                updateThemeColor("#6A54D0");
                break;
            case "/verify-otp":
                updateThemeColor("#6A54D0");
                break;
            case "/main":
                updateThemeColor("#F4F4F4");
                break;
            case "/times":
                updateThemeColor("#ffffff");
                break;
            default:
                updateThemeColor("#695CD5"); // Default color
        }
    }, [location]);

    return null; // No UI rendered
};
export default ThemeColorHandler;
