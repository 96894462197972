import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setActiveTab } from "../../store/tabSlice";

import HomeActiveIcon from "../../assets/icons/home-active.svg";
import HomeInactiveIcon from "../../assets/icons/home-inactive.svg";
import CalendarActiveIcon from "../../assets/icons/calendar-active.svg";
import CalendarInactiveIcon from "../../assets/icons/calendar-inactive.svg";
import ProfileActiveIcon from "../../assets/icons/profile-active.svg";
import ProfileInactiveIcon from "../../assets/icons/profile-inactive.svg";

interface NavigationMenuProps {
  className?: string;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ className }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: any) => state.tab.activeTab);

  return (
    <div
      className={`d-flex justify-content-center align-items-center fixed-bottom py-3 ${className}`}
      style={{
        width: "90%",
        maxWidth: "393px",
        height: "66px",
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
        margin: "0 auto 26px auto",
      }}
    >
      <div className="d-flex justify-content-around align-items-center w-100 px-4">
        <Link
          to="/profile"
          className="text-center"
          onClick={() => dispatch(setActiveTab("profile"))}
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <img
            src={
              activeTab === "profile" ? ProfileActiveIcon : ProfileInactiveIcon
            }
            alt="پروفایل"
            style={{ width: "24px", height: "24px" }}
          />
          <p
            className="m-0 small"
            style={{
              color: activeTab === "profile" ? "#695CD5" : "#8D8D8D",
            }}
          >
            پروفایل
          </p>
        </Link>

        <Link
          to="/times"
          className="text-center"
          onClick={() => dispatch(setActiveTab("appointments"))}
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <img
            src={
              activeTab === "appointments"
                ? CalendarActiveIcon
                : CalendarInactiveIcon
            }
            alt="نوبت‌ها"
            style={{ width: "24px", height: "24px" }}
          />
          <p
            className="m-0 small"
            style={{
              color: activeTab === "appointments" ? "#695CD5" : "#8D8D8D",
            }}
          >
            نوبت‌ها
          </p>
        </Link>

        <Link
          to="/main"
          className="text-center"
          onClick={() => dispatch(setActiveTab("home"))}
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <img
            src={activeTab === "home" ? HomeActiveIcon : HomeInactiveIcon}
            alt="خانه"
            style={{ width: "24px", height: "24px" }}
          />
          <p
            className="m-0 small"
            style={{
              color: activeTab === "home" ? "#695CD5" : "#8D8D8D",
            }}
          >
            خانه
          </p>
        </Link>
      </div>

      {/* بخش بلور با گرادیانت */}
      <div
        style={{
          position: "absolute",

          top: 0, // بلور از بالای نوار شروع می‌شود
          left: "50%",
          transform: "translateX(-50%)",
          
          width: "150%", 
          height: "150%", 
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F1F2F6 100%)", // گرادیانت پس‌زمینه
          backdropFilter: "blur(2px)", // افکت بلور
          borderRadius: "24px", // گوشه‌های گرد
          zIndex: -1, // بلور در زیر منو قرار می‌گیرد
        }}
      />
    </div>
  );
};

export default NavigationMenu;
