import React, { useState } from "react";
import Layout from "../components/Layout/Layout"; // اضافه کردن لایوت
import Header from "../components/Header/Header";
import Services from "../components/Services/Services";
import HairdresserList from "../components/HairdresserCard/HairdresserList"; // وارد کردن کامپوننت HairdresserList
import NavigationMenu from "../components/NavigationMenu/NavigationMenu";
import ReservedTimes from "../components/Reserved/ReservedTimes";

// تعریف نوع ReservedTime
interface ReservedTime {
  id: number;
  title: string;
  stylist: string;
  date: string;
  time: string;
  stylistImage: string;
  isPaid: boolean;
  start: string; // ویژگی جدید برای زمان شروع
  end: string;
}

const MainPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  // const reservations: ReservedTime[] = []; // آرایه خالی برای تست

  const reservations: ReservedTime[] = [
    {
      id: 1,
      title: "رنگ و لایت",
      stylist: "اکرم اصغری تهرانی اصل",
      date: "۱۴۰۲/۰۲/۰۵", // تاریخ شمسی
      time: "۱۰:۳۰ صبح",
      stylistImage: "https://via.placeholder.com/64",
      isPaid: false,
      start: "10:00", // زمان شروع
      end: "12:00", // زمان پایان
    },
    {
      id: 2,
      title: "کوتاهی مو",
      stylist: "زهرا کریمی",
      date: "۱۴۰۲/۰۲/۱۰", // تاریخ شمسی
      time: "۲:۰۰ بعد از ظهر",
      stylistImage: "https://via.placeholder.com/64",
      isPaid: true,
      start: "14:00", // زمان شروع
      end: "16:00", // زمان پایان
    },
  ];

  return (
    <Layout>
      <div className="pb-8">
        <Header onSearch={handleSearch} />
      </div>

      {/* بخش رزروهای قبلی */}
      <section className="px-3 px-md-4 pb-1 pb-md-8">
        <ReservedTimes reservations={reservations} layout="horizontal" />{" "}
        {/* برای نمایش افقی */}
      </section>

      {/* بخش سرویس‌ها */}
      <section
        style={{
          padding: "0", // حذف محدودیت در چپ و راست
          margin: "0", // حذف فاصله اضافی
        }}
      >
        <Services searchQuery={searchQuery} />
      </section>

      {/* بخش آرایشگرها */}
      <section
        className="pb-2"
        style={{
          padding: "0", // حذف محدودیت در چپ و راست
          margin: "0", // حذف فاصله اضافی
        }}
      >
        <HairdresserList searchQuery={searchQuery} />
      </section>

      {/* نویگیشن بار */}
      <NavigationMenu />
    </Layout>
  );
};

export default MainPage;
