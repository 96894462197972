import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import Layout from "../components/Layout/Layout";
import { useNavigate } from "react-router-dom"; // استفاده از قالب

// تعریف انیمیشن fadeIn
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/help");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: "#695CD5",
          color: "white",
          fontSize: "2rem",
          borderRadius: 0,
          overflow: "hidden",
          boxSizing: "border-box",
          padding: { xs: "10px", sm: "0px" }, // تنظیم padding برای موبایل
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "1.2rem", sm: "clamp(1.5rem, 5vw, 2rem)" }, // تنظیم اندازه پویا
            animation: `${fadeIn} 1.5s ease-in-out`, // انیمیشن fadeIn
            textAlign: "center",
          }}
        >
          Welcome to My App
        </Typography>
      </Box>
    </Layout>
  );
};

export default SplashScreen;
