import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedService } from "../../store/tabSlice"; // ایمپورت اکشن
import "./ServiceItem.css"; // فایل CSS جداگانه برای استایل‌ها

const ServiceItem: React.FC<{ name: string; icon: string }> = ({
  name,
  icon,
}) => {
  const dispatch = useDispatch();

  // استفاده از useCallback برای بهینه‌سازی
  const handleServiceClick = useCallback(() => {
    dispatch(setSelectedService(name)); // ارسال نام سرویس به Redux
  }, [dispatch, name]);

  return (
    <Link
      to={`/times?service=${name}`}
      onClick={handleServiceClick}
      className="service-item-link text-center mx-2"
      aria-label={`سرویس ${name}`}
    >
      <div className="service-item-icon-container">
        <img
          src={icon}
          alt={name}
          className="service-item-icon"
        />
      </div>
      <p className="service-item-name mt-1">{name}</p>
    </Link>
  );
};

export default ServiceItem;
