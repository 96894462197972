// src/store/serviceSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


// src/store/serviceSlice.ts
import HairBrushIcon from "../assets/icons/icon_10.svg";
import KeratinIcon from "../assets/icons/icon_21.svg";
import ScissorsIcon from "../assets/icons/icon_33.svg";
import SkincareIcon from "../assets/icons/icon_42.svg";
import NailPolishIcon from "../assets/icons/icon_35.svg";
import BlowDryIcon from "../assets/icons/icon_46.svg";

interface Service {
  id: number;
  name: string;
  icon: string;
}

interface ServiceState {
  services: Service[];
}

const initialState: ServiceState = {
  services: [
    { id: 1, name: "رنگ و لایت", icon: HairBrushIcon },
    { id: 2, name: "کراتین", icon: KeratinIcon },
    { id: 3, name: "کوتاهی", icon: ScissorsIcon },
    { id: 4, name: "پوست", icon: SkincareIcon },
    { id: 5, name: "ناخن", icon: NailPolishIcon },
    { id: 6, name: "براشینگ", icon: BlowDryIcon },
    { id: 7, name: "ماساژ", icon: HairBrushIcon },
    { id: 8, name: "پاکسازی", icon: KeratinIcon },
    { id: 9, name: "شینیون", icon: ScissorsIcon },
  ],
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices(state, action: PayloadAction<Service[]>) {
      state.services = action.payload;
    },
  },
});

export const { setServices } = serviceSlice.actions;
export default serviceSlice.reducer;
