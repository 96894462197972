import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HairdresserCard.module.css";

interface HairdresserCardProps {
  title: string;
  name: string;
  availability: string; // تاریخ به فرمت فارسی مثل "25 اردیبهشت"
  onBookAppointment?: () => void;
}

// تابع تبدیل تاریخ فارسی به فرمت استاندارد میلادی (YYYY-MM-DD)
const convertPersianDateToISO = (availability: string): string | null => {
  const months = [
    "فروردین",
    "اردیبهشت",
    "خرداد",
    "تیر",
    "مرداد",
    "شهریور",
    "مهر",
    "آبان",
    "آذر",
    "دی",
    "بهمن",
    "اسفند",
  ];

  const [day, monthName] = availability.split(" ");
  const month = months.indexOf(monthName.trim()) + 1; // پیدا کردن شماره ماه
  const year = new Date().getFullYear(); // فرض می‌کنیم سال جاری است

  if (!day || !month) return null;

  // تبدیل به فرمت استاندارد میلادی
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};

const HairdresserCard: React.FC<HairdresserCardProps> = ({
  title,
  name,
  availability,
  onBookAppointment,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const formattedDate = convertPersianDateToISO(availability); // تبدیل تاریخ به فرمت ISO
    if (formattedDate) {
      navigate(`/times?stylist=${encodeURIComponent(name)}&date=${formattedDate}`); // ارسال نام و تاریخ به URL
    } else {
      console.error("Invalid date format for availability:", availability);
    }
  };
  
  
  return (
    <div
      className={`card border-0 text-center rounded-4 ${styles.card}`}
      onClick={handleCardClick} // کلیک برای هدایت به صفحه با فیلتر
      style={{ cursor: "pointer" }}
    >
      <p className={`text-muted small mb-1 ${styles.title}`}>{title}</p>
      <h4 className={`${styles.name} ${styles.truncate}`}>{name}</h4>
      <div className={styles.availabilityContainer}>
        <div className={styles.icon}>
          <span>&lt;</span>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.textSmall}>اولین وقت خالی:</p>
          <p className={styles.textLarge}>{availability}</p>
        </div>
      </div>

      {onBookAppointment && (
        <button
          className={`btn btn-primary ${styles.button}`}
          onClick={onBookAppointment}
        >
          رزرو وقت
        </button>
      )}
    </div>
  );
};

export default HairdresserCard;
